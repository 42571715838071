import axios from 'axios';
import store from '../redux/store';

const api = axios.create({ timeout: 40000 });

export function getUrlAtiva() {
  const state = store.getState();

  return state.cfgGeral.urlRemota;
}

api.interceptors.request.use(async (config) => {
  const state = store.getState();

  config.baseURL = getUrlAtiva();

  const token = state.sessao.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const codigoEmpresa = state.sessao.empresa?.codigoEmpresa;
  if (codigoEmpresa) {
    config.headers.codigo_empresa = codigoEmpresa;
  }

  return config;
});

export default api;
