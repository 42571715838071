import { Card, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardTotalizadores from '../../Components/generic/CardTotalizadores';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import apiPedidoLV from '../../api/apiPedidoLV';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import {
  STATUS_PEDIDO_EDCLIENTE_APROVADO,
  STATUS_PEDIDO_EDCLIENTE_CANCELADO,
  STATUS_PEDIDO_EDCLIENTE_EM_ANALISE
} from '../../constants';
import { isMobile, mostrarToast } from '../../helper';
import ButtonGeneric from '../../Components/generic/ButtonGeneric';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const CardsPedido: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sessao] = useSessao();

  const [totalCancelados, setTotalCancelados] = useState(0);
  const [totalEmAnalise, setTotalEmAnalise] = useState(0);
  const [totalAprovados, setTotalAprovados] = useState(0);

  const codigoCliente = sessao?.clienteUsuario?.codigoCliente;
  const codigoEmpresa = sessao?.empresa?.codigoEmpresa;

  const getPedidos = async () => {
    try {
      setLoading(true);
      const res = await apiPedidoLV.getMany({
        where: { codigoCliente: codigoCliente, codigoEmpresa: codigoEmpresa }
      });

      const pedidosCancelados = res.filter(
        (item) => item.statusLV === STATUS_PEDIDO_EDCLIENTE_CANCELADO
      );
      const pedidosEmAnalise = res.filter(
        (item) => item.statusLV === STATUS_PEDIDO_EDCLIENTE_EM_ANALISE
      );
      const pedidosAprovados = res.filter(
        (item) => item.statusLV === STATUS_PEDIDO_EDCLIENTE_APROVADO
      );

      setTotalCancelados(pedidosCancelados?.length || 0);
      setTotalEmAnalise(pedidosEmAnalise?.length || 0);
      setTotalAprovados(pedidosAprovados?.length || 0);
    } catch (error) {
      console.error(error);
      mostrarToast(`Falha ao buscar pedidos: ${error}`, 'error');
      return;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPedidos();
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: '0', md: '30px' },
          padding: '10px',
          borderRadius: '12px'
        }}
      >
        <Card
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '50px',
            padding: '10px'
          }}
        >
          <Typography fontSize={20} style={{ textAlign: 'center' }}>
            Meus pedidos
          </Typography>
          {isMobile() ? (
            <Paper elevation={4} style={{ borderRadius: '20px' }}>
              <IconButton
                onClick={() => {
                  navigate('/novoPedido');
                }}
              >
                <AddShoppingCartIcon color="success" />
              </IconButton>
            </Paper>
          ) : (
            <ButtonGeneric
              label={'Novo pedido'}
              variant="outlined"
              startIcon={<AddShoppingCartIcon />}
              onClick={() => {
                navigate('/novoPedido');
              }}
            />
          )}
        </Card>
      </Grid>

      <CardTotalizadores
        titulo={'Pedidos cancelados'}
        value={totalCancelados.toString()}
        icon={<CancelIcon sx={{ fontSize: '40px' }} />}
        backgroundColor={'#c62828'}
        color={'whitesmoke'}
        telaDeNavegacao="pedido"
        loading={loading}
        onClick={() => {
          navigate('/pedido');
        }}
      />
      <CardTotalizadores
        titulo={'Pedidos em análise'}
        value={totalEmAnalise.toString()}
        icon={<HourglassBottomIcon sx={{ fontSize: '40px' }} />}
        backgroundColor={'#FF8C00'}
        color={'whitesmoke'}
        telaDeNavegacao="pedido"
        loading={loading}
        onClick={() => {
          navigate('/pedido?tipo=1');
        }}
      />
      <CardTotalizadores
        titulo={'Pedidos aprovados'}
        value={totalAprovados.toString()}
        icon={<CheckCircleIcon sx={{ fontSize: '40px' }} />}
        backgroundColor={'#006400'}
        color={'whitesmoke'}
        telaDeNavegacao="pedido"
        loading={loading}
        onClick={() => {
          navigate('/pedido');
        }}
      />
    </>
  );
};

export default CardsPedido;
